<template>
  <div>
    <b-row>
      <b-col lg="6">
        <h2 class="mb-4 font-weight-bold">
          <small><i class="fe fe-flag"></i></small> Pages <a-divider type="vertical" />
          <span v-if="user.portals.length && settings.activePortal" class="text-muted small">
            {{ user.portals.filter(portal => portal.id === settings.activePortal)[0].title}}
          </span>
        </h2>
      </b-col>
      <b-col class="text-lg-right mb-4 mb-lg-0" lg="6">
        <router-link v-if="$route.path !== '/pages'" :to="{ path: '/pages' }">
          <i class="fe fe-arrow-left"></i> Back
        </router-link>
        <Acl v-else tag="router-link" portal-required :permissions="['pages-create']" :to="'/pages/create'" class="btn btn-success mr-2">
          Create New Page
        </Acl>
      </b-col>
    </b-row>
    <router-view />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Acl from '@/components/cleanui/system/ACL'
export default {
  computed: {
    ...mapState(['settings', 'user']),
    // ...mapState('portal', ['data']),
  },
  components: {
    Acl,
  },
}
</script>
